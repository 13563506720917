import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { GlobalVariable } from "src/app/config/global"
import { trigger, state, transition, style, animate } from "@angular/animations"
import { EpaycoService } from "src/app/service/epayco.service"
import { EpaycoPayment } from "src/app/model/epaycoPayment"
import { MatDialog } from "@angular/material"
import { PaymentinfoDialogComponent } from "../paymentinfo-dialog/paymentinfo-dialog.component"
import { WompiService } from "src/app/service/wompi.service"

@Component({
  selector: "app-transactionresponse",
  templateUrl: "./transactionresponse.component.html",
  styleUrls: ["./transactionresponse.component.scss"],
  animations: [
    trigger("EnterLeave", [
      state("flyIn", style({ transform: "translateY(0%)" })),
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("0.5s 300ms ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
    trigger("EnterLeaveX", [
      state("flyIn", style({ transform: "translateX(0%)" })),
      transition(":enter", [
        style({ transform: "translateX(-200%)" }),
        animate("0.5s 300ms ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class TransactionresponseComponent implements OnInit {
  AppName = GlobalVariable.APP_NAME
  isLoading = false
  ref_payco = ""
  ref_wompi = ""
  paymentInformation: EpaycoPayment
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private epaycoService: EpaycoService,
    private wompiService: WompiService
  ) {}

  ngOnInit() {
    this.route.root.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("ref_payco") != false) {
        this.ref_payco = params.ref_payco !== "" ? params.ref_payco : "0"
        this.getEpaycoPaymentInformation()
      }
      if (params.hasOwnProperty("id") != false) {
        this.ref_wompi = params.id !== "" ? params.id : "0"
        this.getWompiPaymentInformation()
      }
    })
  }

  getEpaycoPaymentInformation() {
    this.isLoading = true
    this.epaycoService.get(this.ref_payco).subscribe(
      (p) => {
        this.paymentInformation = p.success === true ? p.data : null
      },
      (e) => e,
      () => {
        this.isLoading = false
      }
    )
  }

  getWompiPaymentInformation() {
    this.isLoading = true
    this.wompiService.get(this.ref_wompi).subscribe(
      (p) => {
        this.paymentInformation = p.data !== null ? p.data : null
      },
      (e) => e,
      () => {
        this.isLoading = false
      }
    )
  }

  seePaymentInformation() {
    const dialogRef = this.dialog.open(PaymentinfoDialogComponent, {
      width: "350px",
      data: this.paymentInformation,
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // DO SOMETHING
      }
    })
  }

  play() {
    let audio = <HTMLMediaElement>document.getElementById("player")
    audio.src = "https://mialfoliadventista.org/assets/522pista.mp3"
    audio.load()
    audio.play()
  }
}
