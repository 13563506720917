import { Component, OnInit } from "@angular/core"
import { GlobalVariable } from "src/app/config/global"
import { FAQ } from "src/app/model/faq"

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  // Business Unit information
  FAQs: FAQ[] = []
  AppName = GlobalVariable.APP_NAME
  constructor() {}

  ngOnInit() {
    this.loadFAQs()
  }

  loadFAQs() {
    this.FAQs = [
      {
        id: "1",
        title:
          "¿Qué debo hacer para poder usar la plataforma Alfolí Adventista?",
        answer:
          "Usted debe tener una cuenta bancaria o tarjeta débito o crédito. Si la cuenta es de ahorros o corriente debe registrarse primero en PSE para que pueda hacer pagos en línea. También debe validar con su entidad bancaria que puede hacer transacciones por internet.",
        link: "",
        img: "",
      },
      {
        id: "2",
        title:
          "¿Cómo me registro en PSE?",
        answer:
          "Ingrese en el siguiente link y registre sus datos:",
        link: "https://registro.pse.com.co/PSEUserRegister/CreateRegisterPNLG.htm?TipoPersona=0",
        img: "",
      },
      {
        id: "3",
        title:
          "¿Qué medios de pago recibe el Alfolí Adventista?",
        answer:
          "Se reciben todas las tarjetas tanto débito como crédito de cualquier banco o franquicia. Para usar una cuenta de ahorros o corriente para realizar pagos en línea se realiza a través de PSE, el cual acepta los siguiente bancos en Colombia:",
        link: "",
        img: "../../assets/bancos.PNG",
      },
      {
        id: "4",
        title:
          "¿Puedo realizar el pago del Alfolí Adventista en efectivo?",
        answer:
          "No, por el momento sólo se aceptan tarjetas de ahorros, corrientes y créditos, pero puede usar Nequi, DaviPlata o RappyPay a través de PSE.",
        link: "",
        img: "",
      },
      {
        id: "5",
        title:
          "¿Mi iglesia puede recibir donaciones del extranjero?",
        answer:
          "Sí, cualquier persona que tenga tarjeta de crédito Visa, MasterCard o American Express puede realizar donaciones del extranjero.",
        link: "",
        img: "",
      },
      {
        id: "6",
        title:
          "¿Tengo que pagar alguna comisión por la transacción del Alfolí Adventista?",
        answer:
          "No, su banco no le cobrará ninguna comisión.",
        link: "",
        img: "",
      },
      {
        id: "7",
        title:
          "¿Si hago donación por Alfolí Adventista mi iglesia local recibe el dinero?",
        answer:
          "Si, el diezmo y las ofrendas llegarán a la iglesia que seleccione a través de la Asociación o Misión.",
        link: "",
        img: "",
      },
      {
        id: "8",
        title:
          "¿Recibiré un comprobante por mi donación?",
        answer:
          "Sí, tan pronto como finalice el proceso y este es aceptado, el banco envía un soporte de la transacción y la Iglesia Adventista del Séptimo Día le envía un sobre de alfolí en formato pdf al correo electrónico que proporcionó al momento de diligenciar los datos.",
        link: "",
        img: "",
      },
      {
        id: "9",
        title:
          "¿Qué debo hacer si el sobre del alfolí no me llega al correo?",
        answer:
          "Usted debe verificar en la carpeta de spam o correos no deseados. Si allí tampoco aparece, puede ponerse en contacto con el tesorero de iglesia o la Asociación o Misión para que se lo reenvíe.",
        link: "",
        img: "",
      },
      {
        id: "10",
        title:
          "¿Debo enviar el sobre del alfolí al tesorero de mi iglesia?",
        answer:
          "No es necesario. Si el tesorero tiene registrado el correo en la Asociación o Misión, entonces el sobre del alfolí le llegará automáticamente. Sin embargo usted podrá validar con el tesorero si ha recibido el sobre del alfolí y enviarlo.",
        link: "",
        img: "",
      },
      {
        id: "11",
        title:
          "¿Qué debe hacer el tesorero de la iglesia si el sobre del alfolí no le llega al correo?",
        answer:
          "El tesorero de la iglesia debe verificar con el pastor el correo donde fue enviado.",
        link: "",
        img: "",
      },
      {
        id: "12",
        title:
          "¿Qué debo hacer si mi iglesia o grupo no aparece en el listado de mi distrito?",
        answer:
          "Debes escribirnos en contáctenos e indicarnos el distrito y el nombre de la iglesia que no está registrada.",
        link: "",
        img: "",
      },
      {
        id: "13",
        title:
          "¿Por qué mi transacción es rechazada?",
        answer:
          "Una transacción puede ser rechazada por no tener habilitada la opción de pagos en línea; por superar el tope máximo por transacción permitida por su banco; por no tener segunda clave, clave dinámica u otras restricciones impuestas por su entidad financiera.",
        link: "",
        img: "",
      },
      {
        id: "14",
        title:
          "¿Qué debo hacer en caso que la transacción muestre un mensaje de error?",
        answer:
          "Debe enviarnos un mensaje con el error a través de la opción contáctenos.",
        link: "",
        img: "",
      },
      {
        id: "15",
        title:
          "¿Qué garantiza que la transacción realizada por el Alfolí Adventista llega a la Iglesia?",
        answer:
          "La garantía es que el sobre del alfolí que se envía a su correo solo se hace si la transacción es recibida por la iglesia. Además, cada sobre contiene el número de la referencia de la transacción el cual es auditada y procesada por la Asociación o Misión.",
        link: "",
        img: "",
      },
      {
        id: "16",
        title:
          "¿Qué tan segura es la información de mi tarjeta de crédito o débito?",
        answer:
          "El sitio web https://www.mialfoliadventista.org no almacena la información de su tarjeta de crédito o débito. Los pagos se realizan a través de la plataforma de pagos de Bancolombia y cuenta con la máxima certificación de seguridad.",
        link: "https://www.mialfoliadventista.org",
        img: "",
      },
    ]
  }
}
